import React, { Component } from 'react';

import EventView from 'components/News/Event/View';
import EventRelList from 'components/News/Event/RelList';

class EventViewPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };
  }

  componentDidMount() {
    this.setState({
      location: window.location,
    });
  }

  render() {
    const { location } = this.state;
    const id = location !== null ? location.search.substring(1) : null;

    return (
      <div>
        {id !== null && (
          <EventView id={id} />
        )}
        <EventRelList />
      </div>
    );
  }
}

export default EventViewPage;
