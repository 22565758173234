import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';
import _ from 'lodash';
import Ionicon from 'react-ionicons';

import styles from './View.module.scss';
import { eventActions } from '../../../actions';

const cx = classNames.bind(styles);

class View extends Component {

  componentDidMount() {
    this.props.actions.eventActions.request(this.props.id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { event } = nextProps;
    return !event.isProcessing && !event.failure;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.props.actions.eventActions.request(nextProps.id);
    }
  }

  componentWillUnmount() {
    this.props.actions.eventActions.clear();
  }

  render() {
    const { event } = this.props;

    const fromDate = moment(event.data.start_date).format('YYYY.MM.DD');
    const toDate = moment(event.data.end_date).format('YYYY.MM.DD');
    const status = moment().isAfter(event.data.end_date, 'day') ? '종료' : '진행 중';

    return (
      <ScreenClassRender render={(screenClass) => {
        const isMobile = ['xs', 'sm', 'md'].includes(screenClass);
    
        return (
          <div className={cx(['container', { mobile: isMobile }])}>
            {!_.isEmpty(event.data) && (
              <Container>
                <Row>
                  <Col xs={12} md={8} offset={{ md: 2 }}>
                    <div>
                      <div className={cx('meta')}>
                        <span className={cx('status')}>{status}</span>
                        |
                        <span className={cx('date')}>{`${fromDate} ~ ${toDate}`}</span>
                      </div>
                      <div className={cx('title')}>{event.data.title}</div>
                    </div>
                    <img src={event.data.detail_image.url} alt={event.data.title} className={cx('image')} />
                  </Col>
                </Row>
              </Container>
            )}

            <div className={cx('buttonWrapper')}>
              <Link to="/news/event" className={cx('loadMore')}>
                <div className={cx('loadMoreText')}>이벤트 내역</div>
                <Ionicon icon="md-list" fontSize="17px" />
              </Link>
            </div>
          </div>
        );
      }} />
    );
  }
}


/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { event } = state;
  return {
    event,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      eventActions: bindActionCreators(eventActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
